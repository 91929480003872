<template>
	<header>
      <div class="header__area">
         <div class="header__top d-none d-sm-block">
            <div class="container">
               <div class="row align-items-center">
                  <div class="col-xl-6 col-lg-6 col-md-5 d-none d-md-block">
                     <div class="header__welcome">
                        <span v-if="logado.cliente">Bem-vindo <span class="color-theme">{{ dadosUsuario.cliente.login }}</span>!</span>
                        <span v-else>Bem-vindo <span class="color-theme">Visitante</span>!</span>
                     </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-7">
                     <div class="header__action d-flex justify-content-center justify-content-md-end">
                        <ul>
                           <li>
                              <router-link to="/perfil" v-if="logado.cliente">Minha conta</router-link>
                              <router-link to="/login" v-else>Minha conta</router-link>
                           </li>
                           <li>
                              <a href="javascript:;" @click="setRouteAdmin">Área administrativa</a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="header__info">
            <div class="container">
               <div class="row align-items-center">
                  <div class="col-xl-4 col-lg-3">
                     <div class="header__info-left d-flex justify-content-center justify-content-sm-between align-items-center">
                        <div class="logo">
                           <router-link to="/">
                              <img :src="config.logoBlack == null ? '' : config.logoBlack" alt="Logo preta" @error="logoError">
                           </router-link>
                        </div>
                     </div>
                  </div>
                  <div class="col-xl-8 col-lg-9">
                     <div class="header__info-right">
                        <div class="header__search f-left d-none d-sm-block">
                           <form action="javascript:;">
                              <div class="header__search-box">
                                 <input type="text" placeholder="Pesquisar..." v-model="pesquisa.valor">
                                 <button @click="pesquisar">Pesquisar</button>
                              </div>
                              <div class="header__search-cat">
                                 <select id="select-categoria">
                                    <option value="Todas categorias">Todas categorias</option>
                                    <option v-for="(categoria, index) in listaCategorias" :key="index" :value="categoria.categoria">{{ categoria.categoria }}</option>
                                 </select>
                              </div>
                           </form>
                        </div>
                        <div class="cart__mini-wrapper d-none d-md-flex f-right p-relative">
                           <a href="javascript:;" class="cart__toggle">
                              <span class="cart__total-item">{{ carrinho.quantidadeTotal }}</span>
                           </a>
                           <span class="cart__content">
                              <span class="cart__my">Carrinho:</span>
                              <span class="cart__total-price">R$ {{ parseFloat(carrinho.valorTotal).toFixed(2) }}</span>
                           </span>
                           <div class="cart__mini pb-4 pt-3">
                              <div class="cart__close"><button type="button" class="cart__close-btn"><i class="fal fa-times"></i></button></div>
                              <ul>
                                 <li>
                                    <div class="cart__title pb-2">
                                       <h4>Carrinho</h4>
                                       <span>({{ carrinho.quantidadeTotal + (carrinho.quantidadeTotal > 0 ? ' itens' : ' item') }})</span>
                                    </div>
                                 </li>
                                 <li>
                                    <carrinho v-for="(produto, index) in carrinho.itens" :key="index" :produto="produto" :index="index" :page="'CARRINHO'" />

                                    <div class="cart__item d-flex justify-content-between align-items-center" v-if="carrinho.itens.length == 0">
                                       <div class="cart__inner d-flex mx-auto">
                                          <div class="cart__details">
                                             <h6><a href="javascript:;">Carrinho vazio!</a></h6>
                                          </div>
                                       </div>
                                    </div>
                                 </li>
                                 <li>
                                    <div class="cart__sub d-flex justify-content-between align-items-center">
                                       <h6>Subtotal</h6>
                                       <span class="cart__sub-total">R$ {{ parseFloat(carrinho.valorTotal).toFixed(2) }}</span>
                                    </div>
                                 </li>
                                 <li v-if="carrinho.itens.length != 0">
                                    <!-- <a href="checkout.html" class="t-y-btn w-100 mb-10">Finalizar</a> -->
                                    <router-link to="/finalizar" class="t-y-btn t-y-btn-border w-100">Finalizar</router-link>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         
         <div class="header__bottom">
            <div class="container">
               <div class="row align-items-center">
                  <div class="col-12">
                     <div class="header__bottom-left d-flex d-xl-block align-items-center justify-content-center">
                        <div class="side-menu d-xl-none mr-20 my-2">
                           <button type="button" class="side-menu-btn offcanvas-toggle-btn"><i class="fas fa-bars"></i></button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </header>
</template>

<script>

import $ from 'jquery'
import router from '@/router'
import { mapState } from 'vuex'
import carrinho from '@/components/home/Carrinho.vue'

export default {
	name: 'Header',
   data: function() {
		return {
         pesquisa: {'valor': '', 'categoria': 'Todas categorias', 'subcategoria': 'Todas subcategorias'},
		}
	},
   computed: {
		... mapState({
			listaCategorias: state => state.listaCategorias,
			dadosUsuario: state => state.dadosUsuario,
			logado: state => state.logado,
			carrinho: state => state.carrinho,
			config: state => state.config
		})
	},
   components: {
		carrinho
	},
   methods: {
      logoError : function (e) {
         e.target.src = 'https://cdn.discordapp.com/attachments/795099022439481390/1003983121462599770/logo-black.png';
      },
      setRouteAdmin : function () {
         this.$store.dispatch('isRouteAdmin', true)

         router.push('/admLogin').catch(function(){})
      },
      pesquisar : function () {
         this.pesquisa.categoria = $('#select-categoria').val()
         this.$store.dispatch('pesquisar', this.pesquisa)
      }
   },
   mounted() {
      let ref = this;
      
      this.$store.dispatch('configurarCarrinho')

      $(document).ready(function() {
         $(".offcanvas-toggle-btn").on("click", function () {
            $(".offcanvas__area").addClass("opened");
            $(".body-overlay").addClass("opened");
         });

         $(".cart__toggle").on("click", function () {
            $(".cart__mini").addClass("cart__opened");

            if (ref.carrinho.toggle == false){
               ref.$store.dispatch('toggleCarrinho', true)

            } else if (ref.carrinho.toggle == true) {
               ref.$store.dispatch('toggleCarrinho', false)
            }
         });

         $(".cart__close-btn").on("click", function () {
            ref.$store.dispatch('toggleCarrinho', false)
         });

         $('select').niceSelect();
      });
   }
}

</script>