<template>
   <header>
      <div class="topbar d-flex align-items-center">
         <nav class="navbar navbar-expand">
            <div class="mobile-toggle-menu"><i class='bx bx-menu'></i></div>
            <div class="top-menu ms-auto">
               <ul class="navbar-nav align-items-center">
                  <li class="nav-item">
                     <a class="nav-link position-relative" href="javascript:;" role="button" @click="setRouteCliente" title="Voltar para loja">
                        <i class="far fa-store font-16 color-theme"></i>
                     </a>
                  </li>
               </ul>
            </div>
            <div class="user-box dropdown">
               <a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret pe-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img :src="dadosUsuario.admin.usuarioFoto == null ? '' : dadosUsuario.admin.usuarioFoto" @error="imageError" class="user-img rounded-1 bg-white" alt="Avatar">
                  <div class="user-info ps-3">
                     <p class="user-name mb-0">{{ dadosUsuario.admin.usuarioNome != null ? dadosUsuario.admin.usuarioNome.length >= 15 ? dadosUsuario.admin.usuarioNome.substring(0, 15) + '...' : dadosUsuario.admin.usuarioNome : 'Desconhecido' }}</p>
                     <p class="designattion mb-0">{{ dadosUsuario.admin.usuarioNome != null ? dadosUsuario.admin.usuarioUsername.length >= 15 ? dadosUsuario.admin.usuarioUsername.substring(0, 15) + '...' : dadosUsuario.admin.usuarioUsername : 'Desconhecido' }}</p>
                  </div>
               </a>
               <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                     <a class="dropdown-item" href="javascript:;" @click="abrirEditarFoto">
                        <i class="far fa-camera me-2"></i><span> Editar foto</span>
                     </a>
                  </li>
                  <li>
                     <a class="dropdown-item" href="javascript:;" @click="deslogar">
                        <i class="far fa-sign-out-alt me-2"></i><span> Sair</span>
                     </a>
                  </li>
               </ul>
            </div>
         </nav>

         <!-- Mudar tema -->
         <div class="switcher-wrapper">
            <div class="switcher-btn">
               <i class='bx bx-cog'></i>
            </div>
            <div class="switcher-body">
               <div class="d-flex align-items-center">
                  <h5 class="mb-0">Temas</h5>
                  <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
               </div>
               <hr/>
               <h6 class="mb-3 mt-4">Claro</h6>
               <div class="d-flex align-items-center justify-content-between mb-3">
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="lightmode" :checked="theme == 'light-theme' || theme == null">
                     <label class="form-check-label bg-danger rounded" for="lightmode"></label>
                  </div>
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="primary2mode" :checked="theme == 'primary2-theme'">
                     <label class="form-check-label bg-primary rounded" for="primary2mode"></label>
                  </div>
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="warning2mode" :checked="theme == 'warning2-theme'">
                     <label class="form-check-label bg-warning rounded" for="warning2mode"></label>
                  </div>
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="success2mode" :checked="theme == 'success2-theme'">
                     <label class="form-check-label bg-success rounded" for="success2mode"></label>
                  </div>
               </div>
               <hr/>
               <h6 class="mb-3 mt-4">Escuro</h6>
               <div class="d-flex align-items-center justify-content-between mb-3">
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="dangermode" :checked="theme == 'danger-theme'">
                     <label class="form-check-label bg-danger rounded" for="dangermode"></label>
                  </div>
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="primarymode" :checked="theme == 'primary-theme'">
                     <label class="form-check-label bg-primary rounded" for="primarymode"></label>
                  </div>
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="darkmode" :checked="theme == 'dark-theme'">
                     <label class="form-check-label bg-warning rounded" for="darkmode"></label>
                  </div>
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="successmode" :checked="theme == 'success-theme'">
                     <label class="form-check-label bg-success rounded" for="successmode"></label>
                  </div>
               </div>
            </div>
         </div>

         <!-- modalEditarFoto -->
         <div class="modal fade" id="modalEditarFoto" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalEditarFotoLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
               <div class="modal-content h-100">
                  <div class="modal-header">
                     <h5 class="modal-title" id="modalEditarFotoLabel">Editar foto</h5>
                     <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                     <div class="card mx-1">
                        <div class="card-body">
                           <div class="row">
                              <div class="col-sm-6">
                                 <div class="form-group file-area">
                                    <label for="images"><i class="fal fa-image color-theme font-12 me-1"></i> Foto</label>
                                    <input type="file" name="images" id="images" ref="images" accept=".jpg,.jpeg,.png,.webp" required="required" @change="onFileChange" @drop="onFileChange" @click="onFileChange" />
                                    <div class="file-dummy">
                                       <div class="default">Selecione ou arraste uma foto</div>
                                    </div>
                                 </div>
                              </div>

                              <div class="col-sm-6 mx-auto pt-3 mt-1 position-relative" v-if="imageTemporary != null">
                                 <div class="cropper-box">
                                    <cropper class="cropper" :src="imageTemporary" :stencil-props="{ minAspectRatio: 16/16, maxAspectRatio: 4/4 }" :auto-zoom="true" :backgroundClass="'bg-white'" @change="change" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="modal-footer">
                     <button type="button" class="btn btn-primary" @click="salvarFoto">Salvar</button>
                     <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </header>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'

export default {
	name: 'MenuNavBar',
   data: function() {
		return {
			theme: localStorage.theme,
         imageTemporary: null,
         image: null
		}
	},
   computed: {
      ... mapState({
         errorOnLoadImg: state => state.errorOnLoadImg,
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest,
         logado: state => state.logado
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      setRouteCliente : function () {
         window.location.reload()
      },
		deslogar : function () {
			this.$store.dispatch('deslogarAdmin')
		},
      change : function ({ coordinates, canvas }) {
         coordinates;

         this.$refs.images.value = null;
			this.image = canvas.toDataURL();
		},
      onFileChange(e) {
         var files = e.target.files || e.dataTransfer.files;
         if (!files.length)
         return;
         this.createImage(files[0]);
      },
      createImage(file) {
         var reader = new FileReader();
         var vm = this;

         reader.onload = (e) => {
            vm.imageTemporary = e.target.result;
         };
         reader.readAsDataURL(file);
      },
      abrirEditarFoto : function () {
         this.imageTemporary = null
         this.image = null

         $('#modalEditarFoto').modal('show')
      },
      salvarFoto : function () {
         if (this.image == null || String(this.image).length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Foto inválida!'
            })

            return
         }

         let ref = this;
			this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'adminuser/changephoto',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername,
					'Content-Type': 'application/json'
            },
				data: JSON.stringify({
               idUsuario: this.dadosUsuario.admin.usuarioId,
               foto: this.image
            })
         }).then(function () {
				$('#modalEditarFoto').modal('hide')
            ref.dadosUsuario.admin.usuarioFoto = ref.image

            ref.$toast.fire({
               icon: 'success',
               title: 'Foto alterada!'
            })
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      }
	}
}

</script>

<style scoped>

.form-check label {
	border: 1px solid #000;
	height: 25px;
	width: 25px;
	display: flex;
}

</style>