import Vue from 'vue'
import $ from 'jquery'
import store from '@/store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
   // Cliente
   {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue')
   },
   {
      path: '/finalizar',
      name: 'Finalizar',
      component: () => import('../views/Finalizar.vue')
   },
   {
      path: '/login',
      name: 'Login',
      component: () => import('../views/Login.vue')
   },
   {
      path: '/registro',
      name: 'Registro',
      component: () => import('../views/Registro.vue')
   },
   {
      path: '/perfil',
      name: 'Perfil',
      component: () => import('../views/Perfil.vue')
   },
   {
      path: '/produtos',
      name: 'Produtos',
      component: () => import('../views/Produtos.vue')
   },
   {
      path: '/produto/:produto',
      name: 'Produto',
      component: () => import('../views/Produto.vue')
   },
   // Admin
   {
      path: '/admLogin',
      name: 'AdmLogin',
      component: () => import('../views/admin/Login.vue')
   },
   {
      path: '/admPaginaInicial',
      name: 'AdmPágina_Inicial',
      component: () => import('../views/admin/PaginaInicial.vue')
   },
   {
      path: '/admProdutos',
      name: 'AdmProdutos',
      component: () => import('../views/admin/Produtos.vue')
   },
   {
      path: '/admPedidos',
      name: 'AdmPedidos',
      component: () => import('../views/admin/Pedidos.vue')
   },
   {
      path: '/admConfiguracoes',
      name: 'AdmConfigurações',
      component: () => import('../views/admin/Configuracoes.vue')
   },
   {
      path: '/admVendas',
      name: 'AdmVendas',
      component: () => import('../views/admin/Vendas.vue')
   },
   {
      path: '/admVips',
      name: 'AdmVips',
      component: () => import('../views/admin/Vips.vue')
   },
   {
      path: '/admSalarios',
      name: 'AdmSalários',
      component: () => import('../views/admin/Salarios.vue')
   },
   {
      path: '/admTickets',
      name: 'AdmTickets',
      component: () => import('../views/admin/Tickets.vue')
   },
   {
      path: '/admAdvertencias',
      name: 'AdmAdvertências',
      component: () => import('../views/admin/Advertencias.vue')
   },
   {
      path: '/admPesquisa',
      name: 'AdmJogadores_pesquisa',
      component: () => import('../views/admin/JogadoresPesquisa.vue')
   },
   {
      path: '/admVeiculos',
      name: 'AdmJogadores_veículos',
      component: () => import('../views/admin/JogadoresVeiculos.vue')
   },
   {
      path: '/admPermissoes',
      name: 'AdmJogadores_permissões',
      component: () => import('../views/admin/JogadoresPermissoes.vue')
   },
]

const router = new VueRouter({
   mode: 'abstract',
   base: process.env.BASE_URL,
   routes
})

router.beforeEach((to, from, next) => {
   store.dispatch('isRouteAdmin', to.path.startsWith('/adm'))

   $(".offcanvas__area").removeClass("opened");
   $(".body-overlay").removeClass("opened");

   next()
})

export default router
